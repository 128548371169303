import React, { useEffect, memo, useState } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import localforage from "localforage";
import { getCookie } from '../utils/cookie';

import Service from "../services/service";
import firebase from "../src/firebase/fire.js";
//import Image from "./image";
import Image from "next/image";
import useSocket from "../hooks/useSocket";

import PubSub from 'pubsub-js'
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import actions from '../redux/actions';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const MenuHeader = ({ isAuthenticated, deauthenticate, getUser, user }) => {

  const classes = useStyles();
  const [showNavigation, setNavigation] = useState(false);
  const socket = useSocket(Service.api);

  /****************************************************/
  /* se o cara esta autenticado chama o getUser */
  /****************************************************/
  useEffect(() => {
    if(isAuthenticated){

      getUser().then(resp => {
      }).catch(e => {
        console.log('MenuHeader => catch => ', e)
      })
    }
  }, [isAuthenticated]); 

  useEffect(() => {

      if(socket){
        socket.on("newFindRegister", (data) => {
          handleMessage(data);
        });
        
        socket.on("newPedidoTelefone", (data) => {
          handleMessage(data);
        });
      }

    return () => {
        if(socket){
          socket.off("newPedidoTelefone");
          socket.off("newFindRegister");
        }
    }
  }, [socket]);

  useEffect(() => {

      if(socket){
        socket.on("newPublishPropose", (data) => {
          playNotification();
          handleMessage(data);
        });
      }

    return () => {
        if(socket){
          socket.off("newPublishPropose");
        }
    }
  }, [socket]);

  const handleMessage = message => {

    let {title, body, icon} = Service.parseHandleMessage(message);

    toast(({ closeToast }) => <div className="d-flex align-items-start">
        <img src={icon} width="30" height="30" alt="logotipo odete" className="rounded-circle image-perfil" />
        <div className="flex-fill text-dark px-2">
          <small className="m-0 d-block"><strong>{title}</strong></small>
          <small>{body}</small>
        </div>
      </div>, {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  };

  const _playNotification = (event) => {
    var audio = document.getElementById('audio-notification');
    audio.play();
  }

  const __playNotification = (event) => {
    var iframe = document.createElement('iframe');
      var html = `<body>
        <audio id="audio-notification" autoplay>
          <source src="https://odete.com.br/assets/audio/sounds_door_bell.mp3" type="audio/mpeg" />
        </audio>
      </body`;

      document.body.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();
  }

  const playNotification = (event) => {
    var audio = document.createElement('audio');
    audio.style.display = "none";
    audio.src = '/assets/audio/sounds_door_bell.mp3';
    audio.autoplay = true;
    audio.onended = function(){
      audio.remove() //Remove when played.
    };
    document.body.appendChild(audio);
  }

  /****************************************************/
  /* ativa as notificacoes */
  /****************************************************/
  const handleNotification = async (event) => {

    var checkRemotePermission = function (permissionData) {
        if (permissionData.permission === 'default') {
            window.safari.pushNotification.requestPermission(
                'https://odete.com.br', // A URL do serviço web.
                'web.com.br.odete.push', // O ID de push do site.
                {}, // Dados que você escolhe enviar ao seu servidor para ajudar a identificar o usuário.
                checkRemotePermission // A função de retorno de chamada.
            );
        }
        else if (permissionData.permission === 'denied') {
            alert('usuário disse não')
        }
        else if (permissionData.permission === 'granted') {
            alert('usuário disse não' + permissionData.deviceToken)
        }
    };

    if ('safari' in window && 'pushNotification' in window.safari) {
        var permissionData = window.safari.pushNotification.permission('web.com.br.odete.push');
        checkRemotePermission(permissionData);
    }

    requestNotification()
  };

  const requestNotification = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {

        try {

          var messaging = firebase.messaging();
          if(!messaging){
            swal('', 'seu aparelho não tem opção de receber a notificações', 'info');
            return false;
          }

          swal('Ativar notificações', 'Clique em "Permitir", no aviso do navegador, para habilitar as notificações', 'info').then(() => {

            PubSub.publish('backdrop', true);

            messaging.getToken({vapidKey: 'BBgTLgNqIhmNK9ysVUIUVFAsPjB8FLkGoAUqqjCaz97lMRNHmzLrWMimMPcqsqCLqhpaynuHSboRPgKojsqXAzQ'}).then(async (fcmToken) => {

              if(!fcmToken){
                swal('', 'Infelizmente seu aparelho não tem opção de receber as notificações', 'error');
                return false;
              }

              localforage.setItem("FCM_TOKEN", fcmToken);

              Service.updateToken({token: fcmToken}).then(resp => {
                
                getUser().then(resp => {
                  swal('', 'Notificação ativada com sucesso', 'success');                
                });

              })
            })
            .catch(e => {
              swal('', `Você precisa habilitar ou dar permissão para receber as notificações... ${e}`, 'error');
            }).finally(() => {
              PubSub.publish('backdrop', false);
            });



          });
        }catch(e){
          swal('', 'seu aparelho não tem suporte a notificação firebase', 'info');
          return false;
        }

      } else {
        swal('', 'seu aparelho não tem permissão de receber a notificações', 'info');
      }
    });
  }

  const handleNavigation = () => {
    setNavigation(!showNavigation)
  };

  const goLink = (router, options) => {
    setNavigation(false);

    if(options){
      Router.push(router, options).then(() => window.scrollTo(0, 0));
    }else{
      Router.push(router).then(() => window.scrollTo(0, 0));      
    }
  };

  return (
    <header className="header fixed-top">
      <audio id="audio-notification">
        <source src="/assets/audio/sounds_door_bell.mp3" type="audio/mpeg" />
      </audio>

      <div className="container">
        <div className="d-flex justify-content-between container-fluid align-items-center p-0 topo">
          
          <div className="spacers d-lg-none d-xl-none">
            <button onClick={() => setNavigation(true)} type="button" data-bs-toggle="offcanvas" className="btn btn-sm btn-hambuguer btn-dark rounded-pill" aria-label="menu">MENU</button>
          </div>

          <Link href="/" className="logotipo" prefetch={false}>
              <Image src="/assets/images/logo-odete-header.webp" width="29" height='35' className="me-2 logo" alt="Logotipo Odete" /> <span className="title-logotipo font-odete">Odete</span>
          </Link>

          <div className="navbar-expand-lg nav-pills d-flex justify-content-end" id="menu">
            <nav id="navigation-header-menu" className={'collapse navbar-collapse ' + (showNavigation ? '_show' : '')}>
              <ul className="navbar-nav gap-2">

                  <li className="nav-item">
                    <Link href="/about" prefetch={false} className="nav-link" onClick={() => goLink('/about')}>
                      Como funciona?
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link href="/b2b" prefetch={false}  className="btn btn-block btn-sm btn-primary rounded-pill fw-bold" onClick={() => goLink('/b2b')}>
                      PARA EMPRESAS
                    </Link>
                  </li>
                  {!isAuthenticated && <li className="nav-item">
                    <Link href="/register" prefetch={false} onClick={() => goLink('/register')} className="btn btn-block btn-sm btn-danger rounded-pill fw-bold">
                        QUERO DIVULGAR
                    </Link>
                  </li>}
              </ul>
            </nav>

          {
            !isAuthenticated && 
            <div className="spacers ms-lg-2 ms-0">
              <Link href="/signin" prefetch={false} className="btn btn-block btn-sm btn-dark rounded-pill w-100 fw-bold">
                  ACESSAR
              </Link>
            </div>
          }

          {
            isAuthenticated && <div className="spacers text-end d-flex align-items-center justify-content-end">
              {
                (user && user !== null && firebase?.messaging?.isSupported()) &&
                  <button className="btn" onClick={handleNotification}>
                    {!user.token ? <NotificationsOffIcon /> : <NotificationsActiveIcon className="text-success" />}
                  </button>
              }
              <Link href="/signin" prefetch={false} className="notification-header position-relative text-white">
                {(user && user.imageURL) && 
                  <small className="d-flex align-items-center text-muted">
                    <img alt={user.nome} width='48' height="48" className="image-perfil rounded-circle" src={user.imageURL} />
                  </small>
                }
              </Link>
            </div>
          }
          </div>
        </div>
      </div>

      <SwipeableDrawer anchor="left" open={showNavigation} onClose={() => setNavigation(false)} onOpen={() => setNavigation(true)}>
        <div className={`bg-white h-100 ${classes.list} d-flex flex-column`}>
          <div className="offcanvas-header border-bottom px-2">
            <div className="d-flex align-items-center">
              <Image src="/assets/images/marca_classica_negativo.webp" width="30" height='35' className="me-2 logo" alt="Logotipo Odete" />
            </div>
            <IconButton aria-label="close" onClick={() => setNavigation(false)}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div className="offcanvas-body p-2">
            <ul className="navbar-nav">
                <li className="nav-item mb-2">
                  <small className="barlow fw-bold">NAVEGUE POR</small>
                </li>
                <li className="nav-item bg-light border rounded ps-3 mb-2">
                  <Link href="/diaristas" prefetch={false} className="nav-link" onClick={() => goLink('/diaristas')}>
                    Cidades
                  </Link>
                </li>
                <li className="nav-item bg-light border rounded ps-3 mb-2">
                  <Link href="/professional" prefetch={false} className="nav-link" onClick={() => goLink('/professional')}>
                    Destaques
                  </Link>
                </li>
                <li className="nav-item">
                  <hr />
                </li>
                <li className="nav-item bg-light border rounded ps-3 mb-2">
                  <Link href="/odete" prefetch={false} className="nav-link" onClick={() => goLink('/odete')}>
                    Sobre
                  </Link>
                </li>
                <li className="nav-item bg-light border rounded ps-3 mb-2">
                  <Link href="/about" prefetch={false} className="nav-link" onClick={() => goLink('/about')}>
                    Como funciona?
                  </Link>
                </li>
                <li className="nav-item bg-light border rounded ps-3 mb-3">
                  <Link href="/b2b" prefetch={false} className="nav-link" onClick={() => goLink('/b2b')}>
                    Para empresas
                  </Link>
                </li>

                {!isAuthenticated && <li className="nav-item">
                  <Link href="/register" prefetch={false} onClick={() => goLink('/register')} className="barlow fw-bold btn btn-block btn-danger w-100">
                      QUERO DIVULGAR
                  </Link>
                </li>}
            </ul>
          </div>
        </div>
      </SwipeableDrawer>
    </header>
  );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user
  }
);

export default connect(mapStateToProps, actions)(memo(MenuHeader));