//import App from 'next/app';
import 'regenerator-runtime/runtime'
//import { Provider } from 'react-redux';
import {useRouter} from 'next/router';
import { wrapper } from '../redux';
import Layout from '../components/layout';
//import initialize from '../utils/initialize';
import * as Sentry from '@sentry/browser';
import '../styles/style.scss';
//import '../styles/globals.css';
import {IntlProvider} from 'use-intl';
import { useLanguage, LanguageProvider } from '../context/LanguageContext';
//import {NextUIProvider} from '@nextui-org/react'
Sentry.init({
    dsn: 'https://e6155996ef874365bd3ae5aa28b41804@sentry.io/1847083'
});

export function reportWebVitals(metric) {
  //console.log(`Odete.com.br -> Report WebVitals -> ${metric?.name} - ${metric?.value}`)
}

const AppContent = ({ Component, pageProps }) => {
  const { locale } = useLanguage(); // Obtém o idioma do contexto

  const {now, ...rest} = pageProps;

  // Carregar mensagens para o idioma atual
  const messages = require(`../locales/${locale}.json`);

  return (
    <IntlProvider locale={locale} messages={messages} now={new Date(now)} timeZone="UTC">
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </IntlProvider>
  );
};



const MyApp = ({ Component, pageProps, router }) => (
  <LanguageProvider initialLocale={router.locale}>
    <AppContent Component={Component} pageProps={pageProps} />
  </LanguageProvider>
)

// class MyApp extends App {
    
//     static async getInitialProps({ Component, ctx }) {
      
//       initialize(ctx);

//       let pageProps = {
//         ...(Component.getInitialProps
//           ? await Component.getInitialProps(ctx)
//           : {})
//       }

//       return {
//         pageProps
//       };
//     }

//     componentDidCatch(error, errorInfo) {
//         Sentry.withScope((scope) => {
//             Object.keys(errorInfo).forEach((key) => {
//                 scope.setExtra(key, errorInfo[key]);
//             });

//             Sentry.captureException(error);
//         });

//         super.componentDidCatch(error, errorInfo);
//     }

//     render() {
//       const { Component, store, pageProps } = this.props;
//       return (
//         <Provider store={store}>
//           <Layout>
//             <Component {...pageProps} />
//           </Layout>
//         </Provider>);
//     }
// }

export default wrapper.withRedux(MyApp);