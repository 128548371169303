import { memo, useEffect, useId, useState } from 'react';

const Index = (props) => {

  const imageFallback = `/assets/images/p.png`;
  const image = props.srcSet || props.src;
  const id = useId();

  const [imageState, setImageState] = useState(imageFallback);

  const loadImage = (url) => {
    const img = new Image(url);
    const el = document.getElementById(id);
    img.onload = () => {
      el.src = url;
      setImageState(url)
    };

    img.onerror = () => {
      el.src=imageFallback;
      setImageState(imageFallback)
    }
    img.src = url;
  }

  useEffect(() => {
    loadImage(image);
  }, []);

  return (
    <figure className={`m-0 ${props.classNameFigure ? props.classNameFigure : '' }`}>
      <picture className="">
        <source
          srcSet={props.srcSet || imageState}
          media="(min-width: 1025px)"
        />
        <img
          alt={props.alt || `imagem ${id} gerada pela api da odete`}
          src={imageState}
          width={props.width}
          height={props.height}
          fetchpriority={props.fetchpriority || "low"}
          onClick={props.onClick}
          style={props.style}
          className={props.className}
          title={props.title || props.alt || 'imagem gerada pela api da odete'}
          loading={props?.fetchpriority === 'high' ? "eager" : "lazy"}
          id={id}
        />
        </picture>
        {
          props.caption && <figcaption className="text-muted">{props.caption}</figcaption>
        }
      </figure>
  )


};

export default memo(Index);