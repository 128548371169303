import Link from "next/link";
import Head from 'next/head'
import { useRouter, Router } from "next/router";
import { useEffect, useState, memo, Profiler } from 'react';
//import Image from "next/image";
import {useFormatter, useNow, useTranslations} from 'use-intl';
import { useLanguage } from '../context/LanguageContext';
import PubSub from 'pubsub-js'

import Service from "../services/service";
import Image from "../components/image";
import Meta from "../components/meta";
import Iframe from "../components/iframe";
import TopPedidos from "../components/topPedidos";
import InternalLinks from "../components/internalLinks";
import ReportsGroup from "../components/reportsGroup";

import SearchHome from "../components/searchHome";
import StatisticsHome from "../components/statisticsHome.server";

import dynamic from 'next/dynamic'
import slugify from 'react-slugify';

const WhoAlreadyHired = dynamic(() => import('../components/whoAlreadyHired'), {ssr: false})
const WhoJustEntered = dynamic(() => import('../components/whoJustEntered'), {ssr: false})
const LastHint = dynamic(() => import('../components/lastHint'), {ssr: false})
const TopFive = dynamic(() => import('../components/topFive'), {ssr: false})
const LastRecomendation = dynamic(() => import('../components/lastRecomendation'), {ssr: false})
const Release = dynamic(() => import('../components/release'), {ssr: false})

import { connect } from 'react-redux';
import actions from '../redux/actions';

import Icon from '@material-ui/core/Icon';

const Index = (props) => {

  function onRenderCallback(
    id, // ID da "árvore" que acabou de ser "commitada"
    phase, // "mount" (quando a árvore está sendo montada) ou "update" (quando a árvore é atualizada)
    actualDuration, // Duração em milissegundos que a renderização demorou
    baseDuration, // Duração estimada para renderização de sub-árvore sem memoização
    startTime, // Quando a renderização começou
    commitTime, // Quando a renderização foi finalizada
    interactions // Conjunto de interações que levaram a essa renderização
  ) {
  console.log(`Renderização do componente ${id} na fase ${phase} levou ${actualDuration.toFixed()}ms`);
  }

  const t = useTranslations();
  const { locale, switchLanguage } = useLanguage();

  const changeLanguage = (lng) => {
    switchLanguage(lng);
  };


  const title = `Odete - Contrate diarista de forma rápida, simples e objetiva`;
  const url = props.url; //`https://odete.com.br/`;
  const description = 'Precisando contratar diarista? Contrate diaristas de forma rápida, simples e objetiva na Odete - A maior plataforma para diarista e contratante do Brasil';
  const keywords = `${title}, serviço de diarista para você, faxina, faxineira, limpeza de casa, limpeza de apartamento, dicas de limpeza, aplicativo para contratar diarista, diaristas em todo brasil, cadastro para trabalhar como diarista, aplicativo serviços domésticos, aplicativo para contratar diarista`;

  const [howWorks, setWorks] = useState('contratante');
  const [search, setSearch] = useState(false);
  const [host, setHost] = useState(null);

  const router = useRouter()

  const partners = [{
    image: 'nano4you-odete.png',
    alt: 'nano4you',
    site: 'https://nano4you.com.br'
  }]

  const servicos = [{
    title: 'Precisa de uma limpeza de escritórios e locais de trabalho?',
    description: 'Organizar a bagunça. Um dos maiores obstáculos para a limpeza do escritório é a quantidade de itens que costumam ficar espalhados por esse espaço. As diaristas da Odete estão altamente capacitadas a realizar o serviço de limpeza de escritórios e locais de trabalho.'
  },
  {
    title: 'Precisa de uma limpeza detalhada?',
    description: 'Limpar o piso (varrer diariamente, passar um pano duas vezes por semana, e uma limpeza mais completa mensalmente); Limpar o fogão (superficialmente todos os dias, limpeza completa uma vez por semana); Tirar os potes de cima do armário e limpar; Limpar as portas dos armários; Limpar o banheiro completamente;'
  },
  {
    title: 'Precisa de uma limpeza padrão?',
    description: 'Consiste em retirar pó e outros resíduos com produtos de higienização mais acessíveis. Esses produtos são aqueles que todos temos em casa e utilizamos no cuidado doméstico: aspirador, vassoura, esfregão, água, sabão, detergente e, no máximo, um desinfetante leve.'
  },
  {
    title: 'Precisa de uma limpeza relacionada a mudanças?',
    description: 'Uma limpeza pré ou pós mudança é geralmente mais pesada do que a limpeza realizada no dia a dia. Isso se deve a muitos fatores relacionados à mudança, como ambientes fechados por muito tempo e empilhamento de caixas de papelão, utilizadas para transportar móveis que serão utilizados.'
  },
  {
    title: 'Precisa de uma limpeza residencial?',
    description: 'A limpeza doméstica inclui organizar e fazer a higiene da casa, dos móveis, das louças, dos eletrodomésticos e das roupas. Muitas vezes, para quem trabalha o dia todo, essa tarefa é uma cansativa segunda jornada.'
  },
  {
    title: 'Precisa de uma faxina?',
    description: 'As diaristas da Odete.com.br estão capacitadas a realizar faxinas de alto padrão em casas, apartamentos e escritórios.'
  },
  {
    title: 'Precisa de uma diarista para lavar roupa?',
    description: 'Cozinhar, lavar e passar: fazem parte da diária de uma diarista? Geralmente, não. Empresas que oferecem os serviços de diarista afirmam que cozinhar, lavar e passar roupa são funções que exigem a mão de obra de outros profissionais, ou a disponibilidade de mais horas de trabalho. Consulte sua diarista!'
  },
  {
    title: 'Precisa de uma diarista para cozinhar?',
    description: 'A ideia é cobrar por dia e em algumas situações fechar pacotes semanais, como o de cozinhar três vezes por semana para uma casa especificamente. O valor da cozinheira diarista fica entre R$50 e R$100 e os pacotes de três dias podem variar entre R$120 e R$260... Consulte sua diarista!'
  },
  {
    title: 'Preciso de uma personal organizer?',
    description: 'Profissional que presta serviços para organizar todos os cômodos da casa. A Personal Organizer é uma profissional especializada em trazer mais praticidade para o dia a dia de pessoas e empresas ao organizar ambientes. Essa profissional precisa ter capacitação técnica para oferecer esse serviço.'
  },
  {
    title: 'Preciso de uma diarista urgente!',
    description: 'Se você precisa de uma diarista urgente, coloque o seu CEP na plataforma da Odete e ela te retornará as diaristas mais próximas de você! Você poderá visualizar o perfil de cada uma delas e entrar em contato diretamente, podendo combinar data, hora, forma de pagamento, valor e os serviços que serão executados em sua casa.'
  },
  {
    title: 'Como faço para me cadastrar para trabalhar como diarista?',
    description: 'Se você quer trabalhar como diarista, cadastre o seu perfil na plataforma da Odete e receba as ofertas no seu e-mail e no telegram, você também poderá participar do grupo de WhatsApp, recebendo dicas, orientações e informações úties para o seu desenvolvimento profissional.'
  }
  ];

  useEffect(() => {
    var hosts = {
      'kukativa': {
        image: 'kukativa-odete.png',
        description: 'É uma satisfação dar as boas-vindas e indicar os serviços de profissionais diaristas cadastradas na Odete para você, algo que preenche a Kukativa de muito orgulho.'
      },
      'oleak': {
        image: 'oleak-odete.png',
        description: 'É uma satisfação dar as boas-vindas e indicar os serviços de profissionais diaristas cadastradas na Odete para você, algo que preenche a Oleak de muito orgulho.'
      },
      'nano4you': {
        image: 'nano4you-odete.png',
        description: 'É uma satisfação dar as boas-vindas e indicar os serviços de profissionais diaristas cadastradas na Odete para você, algo que preenche a Nano4You de muito orgulho.'
      },
      'bralimpia': {
        image: 'bralimpia-odete.png',
        description: 'É uma satisfação dar as boas-vindas e indicar os serviços de profissionais diaristas cadastradas na Odete para você, algo que preenche a Bralimpia de muito orgulho.'
      },
      'desembala': {
        image: 'desembala-odete.png',
        description: 'É uma satisfação dar as boas-vindas e indicar os serviços de profissionais diaristas cadastradas na Odete para você, algo que preenche a Desembala de muito orgulho.'
      }
    }
    var subdomain = document.location.host.split(".")[0]
    var h = hosts[subdomain];

    if(h){
      setHost(h)
    }
  }, [])

  const contratar = async (event) => {

    event.preventDefault();

    PubSub.publish("openModalDisparo");
    return;

    setSearch(true);

    Service.getMarker().then(response => {
      const result = Service.parseMarkerAddress(response[0]);

      var region = result?.uf;
      var cidade = slugify(result?.cidade).toLowerCase();
      result.latitude = result.loc[1];
      result.longitude = result.loc[0];

      router.push({ pathname: `/diaristas/${region}/${cidade}`, query: result})
    }).catch(e => {

      document.getElementById('place').focus();

    }).finally(e => {
      setSearch(false);
    });
  }

  var faq = servicos.map((item) => {
    return {
        "@type": "Question",
        "name": item.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `${item.description}`
        }
      }
  });


  return (
    <>      
      <Head>
 
       <Meta />

        <title key="title">{title}</title>

        <meta property="og:description" content={description}  />
        <meta property="og:image:alt" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={url}  />

        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <link rel="canonical" href={url}  />
        <link rel="alternate" href={url} hrefLang="x-default" />
        <meta name="google-site-verification" content="5VmZFir11F2XLpbraZhqb0uriUTBtBm-ZO6kpXVsQ54" />
        
        <script type="application/ld+json" dangerouslySetInnerHTML={ { __html: `
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": ${JSON.stringify(faq, null, '\t')}
          }
        `}} />

        <script type="application/ld+json" dangerouslySetInnerHTML={ { __html: `{
          "@context": "https://schema.org/",
          "@type": "Organization",
          "name": "Odete",
          "legalName": "Odete Serviços Digitais LTDA",
          "url": "https://odete.com.br",
          "logo": "https://odete.com.br/assets/images/marca_classica_negativo.webp",
          "foundingDate": "2019",
          "sameAs" : [
            "https://instagram.com/odetediarista",
            "https://www.facebook.com/odete.com.br",
            "https://www.youtube.com/channel/UCbS-hNOkZl7xvr1XcN1CoVw",
            "https://www.linkedin.com/company/odete"
          ],
          "contactPoint": {
            "@type" : "ContactPoint",
            "email": "odete@odete.com.br",
            "contactType": "Fale comigo"
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Rua Vicenti Artini, 16",
            "addressLocality": "Bairro Alto",
            "addressRegion": "SP",
            "postalCode": "18800-090",
            "addressCountry": "BR"
          },
          "description": "Precisando contratar diarista? Contrate diaristas de forma rápida, simples e objetiva na Odete - A maior plataforma para diarista e contratante do Brasil"
        }`}} />
      </Head>

      {/*hero */}
      <aside className="hero d-flex align-items-sm-center" id="topo">
        <section className="container p-4">

            <div className="row align-items-start align-items-sm-center">
              {
              host && <div className="col-12 col-md-6 text-center mb-4 offset-md-3">
                <Image src={`/assets/images/hosts/${host.image}`} className="mw-100 mb-3" />
                <p className="_lead">{host.description}</p>
                </div>
              }


              <div className="col-12">
                <h1 className="fw-bold text-sm-center">
                  Precisando contratar <strong className="text-danger">diaristas</strong> de forma rápida, simples e objetiva?
                </h1>
                <h2 className="d-block lead text-secondary text-sm-center">
                  Precisando contratar uma diarista? Contrate e negocie diretamente com profissionais diaristas, <mark className="p-0 d-inlineblock lh">sem taxas de intermediação!</mark>
                </h2>
                <h3 className="d-block text-sm-center">Pague diretamente para a diarista, <mark className="p-0 d-inlineblock lh">sem taxa de intermediação</mark> a pessoa ganha muito mais!</h3>
                <SearchHome data={props?.data} />
                <StatisticsHome data={props?.data} />
              </div>
            </div>
        </section>
      </aside>


      <main id="conteudo">

        {props?.data?.users && props?.data?.users?.length > 0 && <div className="py-4 how-works border-top position-relative">
          <ReportsGroup />
          <div className="container py-4">
           <h3 className="text-center lead fw-bold">Últimas {props.data.users.length} pessoas que usaram a Odete neste momento</h3>
           <p className="text-muted text-center">tenha essa experiência você também</p>
           <div className="d-flex table-responsive gap-2 position-relative mb-4 snap">
             {props.data.users.map(item => {
              return (<div key={item._id} className="snap-item">
                <Image src={item.imageURL} alt={`Contratante ${item.nome}`} width="50" height="50" className="rounded" />
              </div>)
             })}
           </div>
           <button type="button" disabled={search} onClick={contratar} className="btn text-center btn-primary btn-lg fw-bold rounded-pill text-uppercase m-auto d-block px-4">{search ? '...' : 'Quero usar também'}</button>
          </div>
        </div>}


        <div className="py-4 how-works border-top border-bottom position-relative">
          <div className="container py-4">
            <TopPedidos />
          </div>
        </div>


        <div className="py-4 bg-dark position-relative">
          <div className="container py-4 text-center text-white">
            <h2>Sua empresa está sabendo da novidade?</h2>
                <p className="lead">conheça o benefício que está revolucionando as empresas</p>
                <Link href="/b2b" className="btn btn-primary btn-lg">VALE ODETE</Link>
          </div>
        </div>



        {/* como funciona - puro html */}
        <div className="py-4 how-works border-top border-bottom position-relative bg-light">
          <div className="container py-4">
            {/*howWorks && <img alt="como funciona?" src={`${howWorks === 'contratante' ? '/assets/images/como-fuciona-contratantes.png' : '/assets/images/como-fuciona-diaristas.png'}`} className="h-100 position-absolute top-50 end-0 translate-middle-y d-none d-sm-none d-md-none d-lg-none d-xl-block" />*/}
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="fw-bold">Quero usar a plataforma!</h3>
                <p>você é contratante ou diarista?</p>
                <div className="d-flex gap-2 justify-content-center">
                  <button className={`btn btn-outline-primary rounded-pill px-4 ${howWorks === 'contratante' ? 'active' : ''}`} type="button" onClick={() => setWorks('contratante')}>sou contratante</button>
                  <button className={`btn btn-outline-danger rounded-pill px-4 ${howWorks === 'diarista' ? 'active' : ''}`} type="button" onClick={() => setWorks('diarista')}>sou diarista</button>
                </div>
              </div>
              {howWorks && 
                <>
                  <div className="d-flex table-responsive mb-4 mt-4">
                    <div className="col-11 col-sm-6 col-md-4 col-lg-4 col-xl-4 px-2 ps-0">
                      <div className="card p-3 h-100 br-12 bg-white">
                        <div className="position-relative">
                          <Icon className="mb-3">{howWorks === 'contratante' ? 'search' : 'how_to_reg'}</Icon>
                          <h4 className="">{howWorks === 'contratante' ? '1. Pesquise' : '1. Cadastre-se'}</h4>
                          <p className="mb-0">{howWorks === 'contratante' ? 'Digite o endereço de sua região ou utilize sua localização atual, ou navegue pelo seu estado se preferir.' : 'Acesse o formulário de cadastro e preencha as informações do seu perfil'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 px-2">
                      <div className="card p-3 h-100 br-12 bg-white">
                        <div className="">
                          <Icon className="mb-3">{howWorks === 'contratante' ? 'person_search' : 'share'}</Icon>
                          <h4 className="">{howWorks === 'contratante' ? '2. Escolha' : '2. Divulgue'}</h4>
                          <p className="mb-0">{howWorks === 'contratante' ? 'Você terá o resultado de sua pesquisa e poderá escolher os candidatos ou candidatas que achar melhor.' : 'Divulgue o seu perfil nas redes sociais ou com seus atuais clientes. "Chame atenção"'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 px-2 pe-0">
                      <div className="card p-3 h-100 br-12 bg-white">
                        <div className="">
                          <Icon className="mb-3">settings_phone</Icon>
                          <h4 className="">{howWorks === 'contratante' ? '3. Contrate' : '3. Trabalhe'}</h4>
                          <p className="mb-0">{howWorks === 'contratante' ? 'Clique para ver o telefone do candidato ou candidata e negocie a data e o valor do serviço.' : 'Os contratantes interessados no seu perfil entrarão em contato diretamente com você.'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex gap-2 justify-content-center mb-4">
                      {howWorks === 'contratante' && <button disabled={search} type="button" className="btn btn-primary rounded-pill px-4 text-uppercase barlow fw-bold" onClick={contratar}>{search ? '...' : 'Quero pesquisar'}</button>}
                      {howWorks === 'diarista' && <Link href="/register" className={`btn btn-danger rounded-pill px-4 text-uppercase barlow fw-bold`}>Quero Cadastrar</Link>}
                    </div>

                    <div className="ratio ratio-16x9 border border-dark border-5 overflow-hidden d-flex justify-content-center align-items-center position-relative ${props.className}`}" style={{'borderRadius': '15px'}}>
                      {howWorks === 'contratante' && <Iframe id="como-contratante" title="Como faço para contratar um profissional diarista?" className="w-100" width="560" height="315" src="https://www.youtube.com/embed/2x5zL42qMN0?rel=0&controls=0&autoplay=0&mute=0&start=0" />}
                      {howWorks === 'diarista' && <Iframe id="como-diarista" title="Como faço para me cadastrar na plataforma?" className="w-100" width="560" height="315" src="https://www.youtube.com/embed/k95rv5iN4qs?rel=0&controls=0&autoplay=0&mute=0&start=0" />}
                    </div>
                  </div>
                </>
                }
            </div>
          </div>
        </div>

        {howWorks && <div className="d-flex table-responsive">
          <div className="ratio ratio-1x1">
            {howWorks === 'contratante' && <Iframe id="depoimento-contratante" title="Depoimentos de Contratante" className="w-100" width="560" height="315" src="https://www.youtube.com/embed/Rxcgr0wSb4k?rel=0&controls=0&autoplay=0&mute=0&start=0&modestbranding=1&showinfo=0" />}
            {howWorks === 'diarista' && <Iframe id="depoimento-diarista" title="Depoimentos de Diarista" className="w-100" width="560" height="315" src="https://www.youtube.com/embed/vJVNy0yEy0Y?rel=0&controls=0&autoplay=0&mute=0&start=0&modestbranding=1&showinfo=0" />}
          </div>
        </div>}


        {/*para todos */}
        <div className="py-4 bg-light border-top">
          <div className="container py-4">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="display-4 barlow fw-bold">O que é Odete?</h3>
                <p>Plataforma que gera oportunidades de trabalho, não somos agência!</p>
              </div>
            </div>
            <div className="row align-items-center d-flex">
              <div className="col-12 text-center">
                <h4 className="barlow fw-bold text-uppercase">a maior plataforma de profissionais diaristas</h4>
                <p className="lead">A Odete veio para mudar a história do Brasil. Gerar oportunidade e incluir digitalmente 6.5 milhões de profissionais diaristas.</p>
                <p className="lead">Aqui elas podem divulgar o serviço de profissionais diaristas, recebem avaliação e recomendação e negociam a <mark>data, hora, valor, formas de pagamento diretamente com vocês contratantes!</mark></p>
                <h5 className="fw-bold barlow text-uppercase">Sem taxa de intermediação - Isso é o justo!</h5>
                <p className="lead m-0">Contrate profissionais diaristas de forma rápida, simples e objetiva!</p>
              </div>
            </div>
            </div>
        </div>




        {
          howWorks && 
            <div className="py-4 border-bottom">
            <div className="container py-4">
              <div className="row align-items-center d-flex justify-content-center">
                <div className="col-12 text-center">
                  <h3 className="display-4 barlow fw-bold mb-5">O que você deseja em sua cidade?</h3>
                  <div className="d-flex justify-content-center gap-3">
                   <Link href="/register" className="btn btn-danger rounded-pill text-nowrap px-4">trabalhar</Link>
                   <button disabled={search} className="btn btn-primary rounded-pill text-nowrap px-4" type="button" onClick={contratar}>{search ? '...' : 'contratar'}</button>
                  </div>
                </div>
              </div>
            </div>
            </div>
        }

        {/* quem ja contratou e quem ja foi contratada */}
        {howWorks === 'diarista' && <WhoAlreadyHired />}
        
        {/* para diaristas - puro html */}
        {howWorks === 'diarista' && <div className="py-4 bg-light text-center">
          <div className="container py-4">
            <div className="row">
              <div className="col-12">
                <h3 className="barlow fw-bold text-uppercase">Você é diarista?</h3>
                <p className="lead">Se você é profissional ou sabe lavar, passar, cozinhar, faxinar e precisa <strong>divulgar o seu serviço</strong> em busca de uma renda extra ou de um trabalho fixo: <strong>Aqui é o seu LUGAR</strong>!</p>
                <p className="lead">Odete é a <strong>maior plataforma para diaristas do Brasil</strong>! Nós ajudamos a divulgar o seu trabalho de forma profissional, é só fazer o seu cadastro e depois utilizar as ferramentas para você ficar ainda mais conhecida.</p>
                <img src="/assets/images/capa-celular-profissionais-diaristas-odete.webp" className="mw-100" alt="Capa Profissionais Diaristas Odete.com.br" />
              </div>
            </div>
          </div>
        </div>}

        {/* quem acabou de entrar */}
        {howWorks === 'diarista' && <WhoJustEntered />}

        {/* Palavra da especialista */}
        {howWorks === 'diarista' && <LastHint />}

        {/* top 5 diaristas */}
        {howWorks === 'diarista' && <TopFive />}

        {/* recomendacoes */}
        {howWorks === 'contratante' && <LastRecomendation />}

        <div className="py-4">
          <div className="container">
            <Profiler id="Release" /*onRender={onRenderCallback}*/><Release /></Profiler>
          </div>
        </div>

        <div className="py-4 pb-0 bg-light">
          <div className="container py-4 text-center">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="barlow fw-bold">Serviços de faxina</h3>
                <p>quais são os serviços de faxina que profissionais diaristas realizam?</p>
              </div>
            </div>
          </div>
          <div className="container">
            {servicos.map((item, index) => <details key={item.title}>
              <summary className="p-3 border-bottom">
                <h4 className="d-inline barlow fw-bold fs-4">{item.title}</h4>
              </summary>
              <p className="py-3 px-4 bg-white barlow m-0 fs-5">{item.description}</p>
            </details>)}
          </div>
        </div>

        <div className="py-4 text-center">
          <div className="container py-4">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="barlow fw-bold text-uppercase">Apoiadores!</h3>
                <p>apoiando a maior plataforma de diarista do Brasil!</p>
              </div>
            </div>
          </div>
          <div className="mb-4 table-responsive d-flex gap-2 justify-content-sm-center snap mx-3">
            {partners.map((item, key) => <a key={item.alt} href={item.site} target="_blank" className="border rounded-2 border-secondary shadow py-1 snap-item"><Image className="mw-100" alt={item.alt} width="200" height="75" src={`/assets/images/partners-logo-beneficios/${item.image}`} /></a>)}
          </div>
        </div>

        <InternalLinks />

      </main>
    </>
  );
}

// Index.getInitialProps = async ({ req }) => {
//   const subdomain = req.headers.host;
//   console.log(subdomain);
//   return { langkey: subdomain };
// };

//export const getStaticProps = async ({ params }) => {
export const getServerSideProps = async ({ pathname, query, req, locale }) => {

    const messages = (await import(`../locales/${locale}.json`)).default
    const protocol = req.headers['x-forwarded-proto'] || 'http';

    // Host (domínio)
    const host = req.headers.host;

    // Caminho (path) da URL
    const path = req.url;

    // URL completa
    const url = `${protocol}://${host}${path}`;
    const ipV4 = req.headers['x-forwarded-for']?.split(',').pop().trim() || req.socket.remoteAddress;
    const ipV6 = req.socket.remoteAddress

    const ipArray = (ipV4 || ipV6).split(":");
    const ip = ipArray[ipArray.length - 1];

    var data = await Service.getInit(ip);

    return {
      props: {data, query, ip, url, messages},
      //revalidate: 1,
    };
}


export default connect(state => state, actions)(Index);