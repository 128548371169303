import { useEffect, useState, memo } from 'react';
import Link from 'next/link';
import Router from "next/router";
import PubSub from 'pubsub-js'

import { logEvent } from '../utils/analytics'
import Image from "./image";
import Service from "../services/service";

import ModalLogin from "./modal";
import ModalPerfil from "./modalPerfil";
import ConfirmationRegister from "./confirmationRegister";
import ConfirmationRegisterPix from "./confirmationRegisterPix";
import FormPayment from "./formPayment";
import Disparo from "./disparo";

import { connect } from 'react-redux';
import actions from '../redux/actions';

import { ToastContainer, toast } from 'react-toastify';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PinterestIcon from '@material-ui/icons/Pinterest';

const Footer = ({ isAuthenticated, deauthenticate, getUser, user, disparos }) => {

  const [texto, setTexto] = useState(Service.helpWhatsAppMessage);
  const [showInstallMessage, setInstall] = useState(false);
  const [online, setOnline] = useState(true);
  const [backdrop, handleToggle] = useState(false);

  /*************************************************************************/
  /* recebe o tipo do evento e o obj */ 
  /*************************************************************************/ 
  const subscribeBackDrop = PubSub.subscribe('backdrop', (type, flag) => {
    handleToggle(flag);
  });

  const handler = () => {}

  useEffect(() => {

    setTexto(Service.parseHandleMessageWhatspp(window.encodeURIComponent(texto)));

    let deferredPrompt;

    window.addEventListener('online', () => setOnline(true));
    window.addEventListener('offline', () => setOnline(false));
    window.addEventListener('appinstalled', (evt) => {
      let addBtn = document.getElementById("installer");
      if(addBtn){
        addBtn.classList.remove('available');      
      }
    });
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      let addBtn = document.getElementById("installer");
      if(addBtn){
        addBtn.classList.add('available');
        addBtn.addEventListener('click', (e) => {

          addBtn.classList.remove('available');
          // Show the prompt
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
              } else {
                console.log('User dismissed the A2HS prompt');
              }
              deferredPrompt = null;
            });
        });
      }
    });

    if (!isIos() && !isInStandaloneMode()) {
      setInstall(true);
    }

    return ()=> {
      window.removeEventListener('online', handler);
      window.removeEventListener('offline', handler);
      window.removeEventListener('appinstalled', handler);
      window.removeEventListener('beforeinstallprompt', handler);      
    }
  }, []);

  // Detecta se o dispositivo está no iOS
  const isIos = () => {
    const userAgent = window?.navigator?.userAgentData?.platform?.toLowerCase() || '';
    return /iphone|ipad|ipod/.test( userAgent );
  }

  useEffect(() => {
    if(isAuthenticated){

      if(user && user != null && !user.userTokenVerify){
        console.log('openModalTelefone -> nao era pra entrar aqui');
        PubSub.publish('openModalTelefone', user)
      }
    }
  }, [isAuthenticated, user])
  
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window?.navigator?.standalone);

  return (
      <>

      {!isAuthenticated && <ModalLogin />}
      {(isAuthenticated && user && user != null) && <ConfirmationRegister />}
      <FormPayment />
      {(isAuthenticated && user && user != null && user.type==='CONTRATADO') && <ConfirmationRegisterPix />}
      <ModalPerfil />

      <footer className="footer position-relative" id="rodape" style={{'paddingBottom': '81px', 'zIndex': 1}}>
        <Disparo />
        <noscript>Seu navegador não tem suporte a JavaScript ou está desativado!</noscript>
        <div className="bg-footer py-4 border-top">
          <div className="container">
            <div className="row py-4">
                <div className="col-12 col-md-3 mb-4">
                  <div>
                    <h4>Ajuda</h4>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link href="/about" prefetch={false} className="nav-link ps-0">
                          Perguntas e respostas
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/terms" prefetch={false} className="nav-link ps-0">
                            Termo de aceite
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/privacy" prefetch={false} className="nav-link ps-0">
                            Política de privacidade
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/contact" prefetch={false} className="nav-link ps-0">
                            Contato
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/release" prefetch={false} className="nav-link ps-0">
                            Imprensa
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/professional" prefetch={false} className="nav-link ps-0">
                            Diaristas em destaques
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link href="/diaristas" prefetch={false} className="nav-link ps-0">
                            Diaristas por estado
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/recomendation" prefetch={false} className="nav-link ps-0">
                            Recomendações
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link href="/hints" prefetch={false} className="nav-link ps-0">
                          Dicas de limpeza
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div>
                    <h4>Redes Sociais</h4>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link ps-0 d-flex align-items-center" rel="noreferrer" href="https://www.instagram.com/odetediarista/" target="_blank">
                          <InstagramIcon width="24" /> Instagram
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link ps-0 d-flex align-items-center" rel="noreferrer" href="https://www.facebook.com/odete.com.br" target="_blank">
                          <FacebookIcon width="24" /> Facebook
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link ps-0 d-flex align-items-center" rel="noreferrer" href="https://www.youtube.com/channel/UCbS-hNOkZl7xvr1XcN1CoVw" target="_blank">
                          <YouTubeIcon width="24" /> YouTube
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link ps-0 d-flex align-items-center" rel="noreferrer" href="https://www.linkedin.com/company/odete/" target="_blank">
                          <LinkedInIcon width="24" /> Linkedin
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link ps-0 d-flex align-items-center" rel="noreferrer" href="https://br.pinterest.com/odetediarista/" target="_blank">
                          <PinterestIcon width="24" /> Pinterest
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link ps-0 d-flex align-items-center" rel="noreferrer" href="https://www.tiktok.com/@odetediarista" target="_blank">
                          TikTok
                        </a>
                      </li>




                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <h4>Atendimento</h4>
                  <div>Segunda a sexta, 9h às 18h</div>
                  <small className="fw-bold barlow d-block mb-2">Ainda está com dúvidas?</small>
                  <Link href="/about" className="btn btn-outline-success rounded-pill barlow fw-bold text-uppercase">
                      Tire suas dúvidas aqui!
                  </Link>
                  {
                    showInstallMessage && 
                    <div id="installer" className="mt-2">
                    <button className="btn btn-outline-info btn-sm">Quero Odete no meu Celular :)</button>
                    </div>
                  }
                  {
                    !online && 
                    <div className="alert alert-danger offline">
                      <small>você está em: modo offline</small>
                    </div>
                  }
                </div>
                <div className="col-12 col-md-3 mb-4">
                  <div className="d-flex justify-content-around flex-md-column align-items-center align-items-sm-start">
                    <a rel="noreferrer" className="sa sat mb-sm-4" href="http://selodigital.imprensaoficial.com.br/validacao/SMPED/011776d0b41935c734" target="_blank" hidden>
                      <Image src="/assets/images/selos/selo-acessibilidade.svg" width="102" height="70" alt="Este site possui um selo de acessibilidade digital." />
                      <p className="m-0">Selo de Acessibilidade Digital</p>
                      <p className="m-0">
                        Nº do Selo: 2020/AD003<br />
                        Validade: <time dateTime="2022-07-31">31/07/2022</time>
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <Link href="#topo" className="btn btn-outline-dark px-4 rounded-pill">topo</Link>
                </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <p className="m-0">
                  O seu endereço de e-mail será usado com a finalidade de oportunidades, notícias e contato da Odete. Mais informações, visite nossa <Link href="/privacy" prefetch={false}>política de privacidade</Link>.
                </p>
                <p>
                  Odete Servicos Digitais LTDA - CNPJ: 36.178.017/0001-80
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ToastContainer />

      {backdrop && <Backdrop className="backdrop" open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>}
    </>
  );
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user,
    disparos: state?.authentication?.disparos || []
  }
);

export default connect(mapStateToProps, actions)(memo(Footer));
